import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Lottie from 'react-lottie'
import LoadingAnimation from 'src/lottie/loadingAnimation.json'

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: 9999,
  },
  hide: {
    display: 'none',
  },
}))

const Loading: React.FC<{ useLoader: boolean }> = ({ useLoader }) => {
  const classes = useStyles()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className={clsx([classes.loader, !useLoader ? classes.hide : ''])}>
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  )
}

export default Loading

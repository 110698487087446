import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppActions, appReducer, Types } from 'src/reducers/AppReducer'
import AppContextInitialState from 'src/types/AppContextInitialState'
import { AlertWarning } from './components/Alert'
import Loading from './components/Loading'

const initialState = {
  state: {
    isAuthenticated: false,
    token: null,
    user: null,
    loading: false,
  },
  dispatch: () => null,
}

const AppContext = React.createContext<{
  state: AppContextInitialState
  dispatch: React.Dispatch<AppActions>
}>(initialState)

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(appReducer, initialState.state)
  const navigate = useNavigate()

  const handleOnIdle = () => {
    if (window.location.pathname !== '/login') {
      dispatch({
        type: Types.Logout,
        payload: {},
      })

      AlertWarning("You've been disconnected due to inactivity", { autoClose: false })
      navigate('/login')
    }
  }

  // Change the last number to set the minutes
  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Loading useLoader={state.loading} />
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        bodyClassName="custom-toast"
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss
        pauseOnHover
        transition={Zoom}
      />
      {children}
    </AppContext.Provider>
  )
}

export const AppConsumer = AppContext.Consumer
export default AppContext

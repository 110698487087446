import { colors, createTheme } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

export interface ITheme {
  palette: {
    primary: {
      main: string
    }
  }
}

const theme = createTheme({
  direction: 'ltr',
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      main: '#46994b',
      light: '#a2cca5',
      dark: '#46994b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#B0DACE',
    },
    text: {
      primary: '#2b2b2b',
      secondary: '#5e5b5a',
    },
  },
  shadows,
  typography,
})

export default theme

import ActionMap from 'src/types/ActionMap'
import AppContextInitialState from 'src/types/AppContextInitialState'
import IUser from 'src/types/User'

export enum Types {
  Authenticate = 'AUTHENTICATE',
  RestoreLogin = 'RESTORE_LOGIN',
  SetUser = 'SET_USER',
  Logout = 'LOGOUT',
  Loading = 'LOADING',
  UpdateUser = 'UPDATE_USER',
}

type AppPayload = {
  [Types.Authenticate]: {
    isAuthenticated: boolean
    user: IUser
    token: string
    refreshToken: string
  }
  [Types.RestoreLogin]: {}
  [Types.SetUser]: { user: IUser }
  [Types.Logout]: {}
  [Types.Loading]: {
    loading: boolean
  }
  [Types.UpdateUser]: {}
}

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>]

export const appReducer = (state: AppContextInitialState, action: AppActions) => {
  switch (action.type) {
    case 'AUTHENTICATE':
      localStorage.setItem('kToken', action.payload.token)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
      localStorage.setItem('kUser', action.payload.user.id)

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      }
    case 'RESTORE_LOGIN':
      const token = localStorage.getItem('kToken')
      const user = localStorage.getItem('kUser')

      return {
        ...state,
        user: { id: user } as IUser,
        isAuthenticated: token && user ? true : false,
        token: token ? token : null,
      }
    case 'LOGOUT':
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}
